$primary: #231d45;
$primary-action: #006eb7;
$primary-green: #94c01e;
$primary-light-green: #dcf596;
$primary-dark-green: #026b00;
$employee-hub-blue: #233976;
$bureau-blue: #083448;
$minerva-blue: #90f2fd;

$ink-02: #fafafb;
$ink-04: #f7f7f7;
$ink-05: #f1f1f4;
$ink-07: #efeff2;
$ink-10: #e6e6e9;
$ink-15: #d5d6dd;
$ink-25: #cccdd3;
$ink-35: #abacba;
$ink-45: #8c8e9c;
$ink-50: #73758c;
$ink-60: #575a75;
$ink-70: #4d4f66;
$ink-85: #242642;
$ink-100: #000324;
$ink-white: #fff;

$ink-05-opacity: rgba(230, 230, 233, 60%);
$system-blue-light-opacity: rgba(194, 228, 252, 40%);

$system-blue: #006eb7;
$system-blue-light: #e7f5ff;
$system-blue-medium: #51b3dd;
$system-blue-dark: #004d80;

$system-red: #cf1337;
$system-red-light: #ffd7de;
$system-red-dark: #8a011b;

$system-green: #94c01e;
$system-green-light: #dcf596;
$system-green-dark: #026b00;

$system-amber: #fdbe00;
$system-amber-light: #ffecb3;
$system-amber-dark: #c58e00;

$system-yellow: #ffe248;
$system-yellow-medium: #fff0a4;
$system-yellow-light: #f7eed9;

$system-orange: #ff9100;
$system-orange-light: #fcd8a6;

$system-off: #8c8e9c;
$system-disabled: #4d4e67;

$system-purple: #462d8f;
$system-purple-light: #eeebff;
$system-purple-medium: #cec8ef;

$system-navy-light: #e3e9f7;

$system-bg-pale-grey-blue: #f1f3fa;
